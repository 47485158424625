<template>
  <div class="w-full">
    <div class="text-base leading-normal ">
      <div v-for="(postCategory, index) in postCategories" :key="postCategory.slug" class="w-full">
        <div class="w-full group }"
          :class="[index===0?'pb-3':'py-3',
          {
            'hover:cursor-default': isCurrentCategory(postCategory.slug),
            'cursor-pointer': !isCurrentCategory(postCategory.slug)
          }]"
          @click="isCurrentCategory(postCategory.slug) ? null : goToCategory(postCategory)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(postCategory.slug),
              'group-hover:text-redAccent':!isCurrentCategory(postCategory.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${postCategory.slug}`) }}
          </span>
        </div>
        <hr v-if="index !== postCategories.length - 1" class="w-full border-grayedHR mb-1" />
      </div>
    </div>
    <!-- Partners -->
    <hr class="-mx-6 my-2 border-dashed border-grayed" />
    <div class="text-base leading-normal ">
      <div :key="'partners'">
        <div class="py-3 group"
             :class="{
            'hover:cursor-default': isCurrentCategory('partners'),
            'cursor-pointer': !isCurrentCategory('partners')
          }"
             @click="isCurrentCategory('partners') ? null : goToCategory({slug: 'partners'})"
        >
          <span
              :class="{
              'font-semibold text-redAccent': isCurrentCategory('partners'),
              'group-hover:text-redAccent':!isCurrentCategory('partners')
            }"
          >
            {{ trans(`POST_CATEGORY:partners`) }}
          </span>
        </div>
      </div>
    </div>
    <!-- Single pages but contact -->
    <hr class="-mx-6 my-2 border-dashed border-grayed" />
    <div class="text-base leading-normal ">
      <div v-for="(postCategory, index) in notCategories" :key="postCategory.slug">
        <div class="py-3 group"
             :class="{
            'hover:cursor-default': isCurrentCategory(postCategory.slug),
            'cursor-pointer': !isCurrentCategory(postCategory.slug)
          }"
          @click="isCurrentCategory(postCategory.slug) ? null : goToCategory(postCategory)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(postCategory.slug),
              'group-hover:text-redAccent':!isCurrentCategory(postCategory.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${postCategory.slug}`) }}
          </span>
        </div>
        <hr v-if="index !== notCategories.length - 1" class="border-grayedHR mb-1" />
      </div>
    </div>
    <!-- Contact -->
    <hr class="-mx-6 my-2 border-dashed border-grayed" />
    <div class="text-base leading-normal ">
      <div v-for="(postCategory) in contactCategory" :key="postCategory.slug">
        <div class="pt-3 group"
             :class="{
            'hover:cursor-default': isCurrentCategory(postCategory.slug),
            'cursor-pointer': !isCurrentCategory(postCategory.slug)
          }"
          @click="isCurrentCategory(postCategory.slug) ? null : goToCategory(postCategory)"
        >
          <span
            :class="{
              'font-semibold text-redAccent': isCurrentCategory(postCategory.slug),
              'group-hover:text-redAccent':!isCurrentCategory(postCategory.slug)
            }"
          >
            {{ trans(`POST_CATEGORY:${postCategory.slug}`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from "@/utils/crud/components/field-types/Text";
import BaseIcon from "@/utils/icons/BaseIcon";
import {mapActions, mapGetters, mapMutations} from "vuex";
import router from "@/router";
import debounce from 'lodash/debounce';
import CloseButton from "@/utils/crud/components/CloseButton.vue";

export default {
  name: "PostsNavigation",
  components: {BaseIcon, CloseButton, TextField},
  data() {
    return {
      postsSearch: null,
    }
  },
  watch: {
	  postsSearch: {
			immediate: true,
			handler() {
				this.searchPostsAction();
			}
	  }
  },
  computed: {
    ...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('posts', ['searchQuery']),

    postCategories() {
      if(this.postCategoriesData) {
        return this.postCategoriesData.filter(el => el.slug === 'news' || el.slug === 'hints' || el.slug === 'faq' );
      }
    },
    notCategories() {
      if(this.postCategoriesData) {
        return this.postCategoriesData.filter(el => el.slug !== 'news' && el.slug !== 'faq' && el.slug !== 'hints' && el.slug !== 'contact' && el.slug !== 'empty');
      }
    },
    contactCategory() {
      if(this.postCategoriesData) {
        return this.postCategoriesData.filter(el => el.slug === 'contact');
      }
    }
  },
  methods: {
    ...mapActions('posts', ['fetchPostsRequest']),
    ...mapActions('dictionaries', ['fetchPostCategoriesData']),
    ...mapMutations('posts', ['setSearchQuery']),
    goToCategory(postCategory) {
      if(postCategory.slug == 'partners') {
        router.push({name: 'partners'})
      } else {
        if(postCategory.hasOwnProperty('onlyPostSlug') && (postCategory.slug==='news' || postCategory.slug==='faq'  || postCategory.slug==='hints')) {
          this.$emit('only-post-clicked', postCategory.onlyPostSlug);
          //return;
        }
        router.push({name: 'posts', params: {postCategory: postCategory.slug}, query: {page: 1}})
      }
    },

    isCurrentCategory(slug) {
      if(slug == 'partners' && this.$route.path.includes('partners')) {
        return true;
      }
      return this.$route.params.postCategory === slug;
    },
    searchPostsAction: debounce(function () {
			if(this.postsSearch) {
				this.setSearchQuery(this.postsSearch);
				this.$router.push({name: 'posts', query: {page: 1, search: this.postsSearch}})
			} else if(this.postsSearch === '') {
				this.$router.push({name: 'posts', params: {postCategory: 'news'}, query: {page: 1}})
			}
    }, 500),

    clearSearchInput() {
      this.postsSearch = null;
	    this.setSearchQuery(null);
	    this.$router.push({name: 'posts', params: {postCategory: 'news'}, query: { page: 1}});
    }
  },
  mounted() {

  }
}
</script>

